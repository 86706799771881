import React, { useState, useRef, useEffect } from 'react';
import './Analyze.css';

function AnalyzeCompanyData({ candidateData }) {
  const [userQuery, setUserQuery] = useState("");
  const [fullConversation, setFullConversation] = useState([]);
  const [typing, setTyping] = useState(false);
  const [currentTypingMessage, setCurrentTypingMessage] = useState("");
  const [displayedConversation, setDisplayedConversation] = useState([]);
  const conversationRef = useRef(null); 
  const userInputRef = useRef(null);

  const handleInputChange = () => {
	setUserQuery(userInputRef.current.textContent);
  };

  const handleGptInteraction = async () => {
	let typingInterval;
	try {
	  const context = [
		{
		  role: "system",
		  content: `You are a helpful assistant. Here is the candidate information: 
					Name: ${candidateData.name}
					Location: ${candidateData.location}
					Current Company: ${candidateData.currentCompany}
					Current Position: ${candidateData.currentPosition}`
		},
		...fullConversation,
		{role: "user", content: userQuery}
	  ];

	  setFullConversation([...fullConversation, {role: "user", content: userQuery}]);
	  setDisplayedConversation([...displayedConversation, {role: "user", content: userQuery}]);
	  userInputRef.current.textContent = "";
	  setTyping(true);

	  let typingIndex = 0;
	  const typingPatterns = ['. ', '.. ', '... '];
	  typingInterval = setInterval(() => {
		setCurrentTypingMessage(typingPatterns[typingIndex]);
		typingIndex = (typingIndex + 1) % typingPatterns.length;
	  }, 400);

	  const response = await fetch(`https://phg.pretiumsearch.com/api/analyze-candidate`, {  // Adjust the endpoint if needed
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json',
		  },
		  body: JSON.stringify({ 
			candidateInfo: candidateData,
			userQuery: userQuery
		  })
		});

	  clearInterval(typingInterval);

	  const data = await response.json();
	  const words = data.message.split(' ');
	  const addWordByWord = (words, index = 0) => {
		if (index < words.length) {
		  setCurrentTypingMessage(prev => `${prev} ${words[index]}`);

		  // Scroll to the bottom
		  if (conversationRef.current) {
			conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
		  }

		  setTimeout(() => addWordByWord(words, index + 1), 100);
		} else {
		  setDisplayedConversation(prev => [...prev, {role: "assistant", content: data.message}]);
		  setCurrentTypingMessage("");
		  setTyping(false);
		}
	  };

	  setCurrentTypingMessage("");
	  addWordByWord(words);
	  setFullConversation([...fullConversation, {role: "assistant", content: data.message}]);
	} catch (error) {
	  clearInterval(typingInterval);
	  console.error('Failed to interact with GPT-4:', error);
	  setTyping(false);
	}
  };

  const handleKeyPress = (event) => {
	if (event.charCode === 13) {  // 13 is the key code for Enter
	  handleGptInteraction();
	}
  };

  useEffect(() => {
	if (conversationRef.current) {
	  conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
	}
  }, [displayedConversation]);

  return (
	<div className="analyze-candidate-data">
	  <div className="chat-analysis-container">
		<div className="ongoing-conversation" ref={conversationRef}>
		  {displayedConversation.map((message, idx) => (
			<div key={idx} className={`message ${message.role}`}>
			  {message.content}
			</div>
		  ))}
		  {typing && <div className="message assistant">{currentTypingMessage}</div>}
		</div>
		<div className="input-wrapper">
		  <div 
			contentEditable="true"
			role="textbox"
			aria-multiline="true"
			data-placeholder="Ask a question about the candidate" 
			ref={userInputRef}
			onInput={handleInputChange}
			onKeyPress={e => handleKeyPress(e)}
			className="editable-div"
		  ></div>
		  <button className={`send-button ${userQuery ? 'active' : ''}`} onClick={handleGptInteraction}>
			➤
		  </button>
		</div>
	  </div>
	</div>
  );
}

export default AnalyzeCompanyData;
