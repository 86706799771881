import './Registration.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserContext'; 
import logo from './logo.png';
import jwt_decode from 'jwt-decode';




function Registration() {
const { loginUser, setIsEmployee  } = useUser();
const navigate = useNavigate();


const handleLogin = async (e) => {
	e.preventDefault();
	const email = e.target.elements.email.value;
	const password = e.target.elements.password.value;

	try {
		const response = await fetch('https://rml.pretiumsearch.com/api/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ username: email, password })
		});

		const responseData = await response.json();  // Extract the JSON data
		console.log("Login Response:", responseData);
		
		if (response.ok) {
			const { token, profilePicture, companyPortalId, candidatePortalId } = responseData;
			localStorage.setItem('authToken', token);
			loginUser(profilePicture); 

			// Decode the JWT to determine if the user is an employee (Optional)
			const decodedToken = jwt_decode(token);
			if (decodedToken.isEmployee) {
				setIsEmployee(true);
				setTimeout(() => {
					navigate('/');
				}, 100);
			}
		    else {
				navigate('/advisorportal');
			}
		} else {
			console.log("Login failed:", responseData.error);
			alert(responseData.error || "Invalid credentials!");  // Display the error from the server
		}
	} catch (error) {
		console.error("Error during login:", error);
		alert("An error occurred during login.");
	}
};



	const handleForgotPassword = () => {
		window.location.href = "https://www.pretiumsearch.com";
	}

	return (
		<div className="Registration">
			<div className="container">
				<div className="leftBox">
					<img src={logo} alt="Pretium Logo" className="logo" />
					<h1 className="PretiumHeader">Portal</h1>
				</div>
				<div className="rightBox">
					<h2 className="dashboardHeader">Welcome to our Portal!</h2>
					<p className="subtitle">Kindly sign in below to view candidate/company information and updates. Should you encounter any issues, please reach out to us at: info@pretiumsearch.com.</p>
					<form id="registrationForm" onSubmit={handleLogin}>
						<div>
							<label>Email:</label>
							<input type="email" name="email" placeholder="Enter your email" required />
						</div>
						<div style={{ marginTop: '20px' }}>
							<label>Password:</label>
							<input type="password" name="password" placeholder="Enter your password" required />
						</div>
						<div style={{ marginTop: '30px' }}>
							<button type="submit">Login</button>
						</div>
						<div style={{ marginTop: '20px' }}>
							<button type="button" className="forgot-password-button" onClick={handleForgotPassword}>Forgot Password?</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Registration;
